
import { defineComponent } from 'vue';
// import { isProd } from '@/config'
import store from '@/store';
import constants from '@/utils/constants';
import Icon from '@/components/Icon.vue';
import ProBadge from '@/components/ProBadge.vue';

const NAV_DATA = {
  [constants.NAV.ACCOUNT]: {
    ICON: 'user',
    TEXT: 'My Account',
    TITLE: 'My Account',
  },
  [constants.NAV.TOKEN_LAUNCH]: {
    ICON: 'gc2',
    TEXT: 'GOOD Token Info',
    TITLE: 'Good Token Info',
  },
  [constants.NAV.SEED_ROUND]: {
    ICON: 'prices',
    TEXT: 'Pre-Seed Round',
    TITLE: 'Community Pre-Seed Round',
  },
  [constants.NAV.SUPPORT]: {
    ICON: 'info',
    TEXT: 'Support',
    TITLE: 'Support',
  },
  [constants.SUBNAV.KYC]: { TEXT: 'KYC' },
  [constants.SUBNAV.ALLOCATION]: { TEXT: 'Allocation' },
  [constants.SUBNAV.CONTRACT]: { TEXT: 'Contract' },
  [constants.SUBNAV.PAYMENT]: { TEXT: 'Payment' },
  [constants.SUBNAV.CONFIRMATION]: { TEXT: 'Confirmation' },
};

const ICONS_BY_STATE = {
  [constants.USER_STATE_TYPES.WAIT]: {
    name: 'wait',
    fill: '#9B9B9B',
    stroke: 'none',
  },
  [constants.USER_STATE_TYPES.OPEN]: {
    name: 'open',
    fill: 'none',
    stroke: '#73AF26',
  },
  [constants.USER_STATE_TYPES.CLOSED]: {
    name: 'closed',
    fill: '#F4825F',
    stroke: 'none',
  },
};

export default defineComponent({
  name: 'Home',
  components: { Icon, ProBadge },

  data() {
    return {
      isNavActive: false,
      isUserMsgVisible: true,
      timer: 0,
      timerTimeout: 0
			// isProd
    };
  },

  computed: {
    CONSTS() {
      return constants;
    },
    NAV_DATA() {
      return NAV_DATA;
    },
    ACTIVE_NAV(): string | undefined {
      return Object.values(constants.NAV).find((key) => {
        return this.$route.matched.some(
          (r) => constants.NAV_ROUTES[key].NAME === r.name
        );
        // return constants.NAV_ROUTES[key].NAME === this.$route.name;
      });
    },

    curRouteData () {
      return Object.values(constants.NAV_ROUTES).find((r) => {
        return r.NAME === String(this.$route.name);
      });
    },

    isPro() {
      return store.getters['user/isPro'];
    },

    activeStateIcon(): Record<string, string> {
      const currentState = store.getters['user/currentState'];
      let icon = ICONS_BY_STATE[constants.USER_STATE_TYPES.OPEN];
      if (
        constants.USER_STATE_BY_TYPES[
          constants.USER_STATE_TYPES.CLOSED
        ].includes(currentState)
      ) {
        icon = ICONS_BY_STATE[constants.USER_STATE_TYPES.CLOSED];
      } else if (
        constants.USER_STATE_BY_TYPES[constants.USER_STATE_TYPES.WAIT].includes(
          currentState
        )
      ) {
        icon = ICONS_BY_STATE[constants.USER_STATE_TYPES.WAIT];
      }
      return icon;
    },

    userMsg(): string {
      return store.getters['user/customMessage'];
    },

  },

	watch: {
		userMsg(val) {
			if (val) {
				this.isUserMsgVisible = true
			}
		}
	},

  methods: {
    isPassedSubnav(NAV: string, SUBNAV: string) {
      const children = this.CONSTS.NAV_ROUTES[NAV].CHILDREN;
      if (!children?.length) return false;
      const currentRouteNav = children.find((r) => {
        return constants.NAV_ROUTES[r].NAME === this.$route.name;
      });
      if (!currentRouteNav) return false;
      const res = children.indexOf(SUBNAV) < children.indexOf(currentRouteNav);
      // eslint-disable-next-line no-debugger
      // debugger
      return res;
    },
    async onSelectState(value: string): Promise<void> {
      await store.dispatch('user/setUserCurrentState', value);
      // this.$router.push('/seed-round');
    },
    calcTimer(): void {
      if (!this.curRouteData || !this.curRouteData.TIMER) {
        this.timer = 0;
        return
      }
      const msTimer = +new Date(store.getters['app/timers'][this.curRouteData.TIMER]) - Date.now()
      this.timer = msTimer;
    },

    calcTimerTimeout (): void {
      this.calcTimer()
      this.timerTimeout = setTimeout(this.calcTimerTimeout, 1000)
    }
  },

  mounted () {
    this.calcTimerTimeout()
  },

  beforeUnmount () {
    clearTimeout(this.timerTimeout)
  }
});
