import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-653b3a98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home__nav" }
const _hoisted_2 = { class: "home__logo-wrap" }
const _hoisted_3 = { class: "home__nav-list" }
const _hoisted_4 = { class: "home__nav-link-text" }
const _hoisted_5 = {
  key: 0,
  class: "home__nav-sub"
}
const _hoisted_6 = { class: "home__nav-sub-text" }
const _hoisted_7 = {
  key: 0,
  class: "home__nav-sub-timer ml1"
}
const _hoisted_8 = ["selected"]
const _hoisted_9 = { class: "home__content" }
const _hoisted_10 = { class: "home__content-header" }
const _hoisted_11 = { class: "container-left" }
const _hoisted_12 = {
  key: 0,
  class: "home__msg"
}
const _hoisted_13 = { class: "home__msg-body mr2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon = _resolveComponent("icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_pro_badge = _resolveComponent("pro-badge")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["home", { 'home--nav-active': _ctx.isNavActive }])
  }, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "btn btn--icon home__logo--menu",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isNavActive = !_ctx.isNavActive))
        }, [
          _createVNode(_component_icon, {
            class: "home__logo-small",
            name: _ctx.isNavActive ? 'close' : 'menu',
            height: 40,
            stroke: "#fff"
          }, null, 8, ["name"])
        ]),
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => [
            _createVNode(_component_icon, {
              class: "home__logo",
              name: "logo-white",
              width: 150,
              height: 16
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CONSTS.NAV, (NAV) => {
          return (_openBlock(), _createElementBlock("li", {
            key: NAV,
            class: "home__nav-el"
          }, [
            _createVNode(_component_router_link, {
              to: _ctx.CONSTS.NAV_ROUTES[NAV].PATH,
              class: _normalizeClass(["home__nav-link", { 'home__nav-link--active': NAV === _ctx.ACTIVE_NAV }])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_icon, {
                  class: "home__nav-link-icon",
                  name: _ctx.NAV_DATA[NAV].ICON,
                  fill: _ctx.$route.name === _ctx.CONSTS.NAV_ROUTES[NAV].NAME ? null : '#9B9B9B'
                }, null, 8, ["name", "fill"]),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.NAV_DATA[NAV].TEXT), 1),
                (NAV === _ctx.CONSTS.NAV.ACCOUNT && _ctx.isPro)
                  ? (_openBlock(), _createBlock(_component_pro_badge, {
                      key: 0,
                      class: "home__nav-link-badge",
                      dark: false
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["to", "class"]),
            (
              _ctx.CONSTS.NAV_ROUTES[NAV].CHILDREN &&
              _ctx.CONSTS.NAV_ROUTES[NAV].CHILDREN.length &&
              _ctx.ACTIVE_NAV === NAV
            )
              ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CONSTS.NAV_ROUTES[NAV].CHILDREN, (SUBNAV) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: SUBNAV,
                      class: "home__nav-sub-el"
                    }, [
                      _createVNode(_component_router_link, {
                        to: { name: _ctx.CONSTS.NAV_ROUTES[SUBNAV].NAME },
                        class: _normalizeClass(["home__nav-link", {
                  'home__nav-sub-el--passed': _ctx.isPassedSubnav(NAV, SUBNAV)
                }])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.NAV_DATA[SUBNAV].TEXT), 1),
                          _createVNode(_component_icon, {
                            class: "home__nav-sub-icon",
                            name: _ctx.activeStateIcon.name,
                            fill: _ctx.activeStateIcon.fill,
                            stroke: _ctx.activeStateIcon.stroke,
                            width: 34,
                            height: 34
                          }, null, 8, ["name", "fill", "stroke"]),
                          (_ctx.curRouteData.NAME === _ctx.CONSTS.NAV_ROUTES[SUBNAV].NAME && _ctx.CONSTS.NAV_ROUTES[SUBNAV].TIMER)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$store.getters['app/timers'][_ctx.CONSTS.NAV_ROUTES[SUBNAV].TIMER]), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "class"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      (_ctx.$store.getters['user/isDev'])
        ? (_openBlock(), _createElementBlock("select", {
            key: 0,
            style: {"position":"fixed","top":"15px","right":"15px","z-index":"9"},
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelectState($event.target.value)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.CONSTS.USER_STATES, (stateName) => {
              return (_openBlock(), _createElementBlock("option", {
                key: stateName,
                selected: stateName === _ctx.$store.getters['user/currentState']
              }, _toDisplayString(stateName), 9, _hoisted_8))
            }), 128))
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.NAV_DATA[_ctx.ACTIVE_NAV].TITLE), 1)
      ]),
      _createVNode(_component_router_view),
      (_ctx.userMsg && _ctx.isUserMsgVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.userMsg), 1),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn--icon home__msg-btn",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isUserMsgVisible = false))
            }, [
              _createVNode(_component_icon, {
                name: "close",
                stroke: "#fff"
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}