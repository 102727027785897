<template>
  <span
    class="pro-badge"
    :class="{ 'pro-badge--dark': dark }"
  >pro</span>
</template>

<script>
export default {
  name: 'ProBadge',
  props: {
    dark: { type: Boolean, default: true },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_vars";

.pro-badge {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 11px;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 2px;
  background: #fff;
  color: #000;
  font-family: $font-default;
  &.pro-badge--dark {
    background: #000;
    color: #fff;
  }
}
</style>
